@import url("./base_variables.css");

/* AUTH PAGES CLASSES*/

.ap-card {
  @apply px-4 py-8 bg-white border border-gray-300 shadow dark:bg-gray-800 sm:rounded-lg sm:px-10 dark:border-gray-600;
}

.ap-heading {
  @apply mt-6 text-lg font-semibold text-center text-gray-900 dark:text-gray-300;
}

.ap-links {
  @apply font-medium text-blue-500 hover:text-blue-600;
}

.ap-buttons {
  @apply flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-blue-500;
}

.ap-input-fields {
  @apply block w-full pl-10 text-gray-900 border-gray-300 rounded dark:text-gray-300 dark:border-gray-500 dark:bg-gray-600 focus:ring-2 focus:ring-indigo-300 dark:focus:border-blue-500 focus:border-blue-500 sm:text-sm;
}

.ap-icons {
  @apply w-5 h-5 text-gray-300 text-gray-700 dark:text-gray-400;
}

/* FORMS */

/* Form Labels */
.form-label {
  @apply block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300;
}

.checkbox {
  @apply w-4 h-4 text-blue-600 bg-gray-200 border-gray-300 rounded cursor-pointer dark:border-gray-500 dark:bg-gray-600 focus:ring-blue-300 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-800;
}

.radio {
  @apply w-4 h-4 text-blue-600 bg-gray-200 border-gray-300 rounded-full cursor-pointer dark:border-gray-500 dark:bg-gray-600 focus:ring-blue-300 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-800;
}

.pininput {
  @apply w-10 text-center text-gray-900 placeholder-gray-300 bg-gray-200 border-gray-300 rounded dark:text-gray-300 focus:ring-indigo-300 focus:border-blue-500 sm:text-sm dark:border-gray-500 dark:bg-gray-600 focus:ring-1 dark:focus:border-blue-500;
}

/* GENERAL ELEMENTS */

.input-field {
  @apply block w-full py-2 pl-4 placeholder-black border border-gray-300 rounded shadow-sm dark:text-gray-300 dark:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500 dark:focus:border-blue-500 sm:text-sm dark:border-gray-500 placeholder-opacity-30 dark:placeholder-white dark:placeholder-opacity-30;
}

.error-input-field {
  @apply block w-full py-2 pl-4 placeholder-red-600 border border-red-300 rounded shadow-sm dark:text-red-300 dark:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-red-300 focus:border-red-500 dark:focus:border-red-500 sm:text-sm dark:border-red-500 placeholder-opacity-30 dark:placeholder-red-600 dark:placeholder-opacity-60;
}

.searchinput {
  @apply block w-full py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-black bg-white border border-gray-300 rounded dark:border-gray-500 placeholder-opacity-30 dark:placeholder-white dark:placeholder-opacity-30 dark:text-white dark:bg-gray-600 focus:ring-2 focus:ring-indigo-300 dark:focus:border-blue-500 focus:border-blue-500 sm:text-sm;
}

.actionbutton {
  @apply inline-flex items-center px-4 py-2 text-base font-medium text-gray-900 bg-gray-200 border border-gray-300 rounded shadow-sm hover:border-gray-700 dark:bg-gray-600 dark:border-gray-500 dark:hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-blue-300;
}

.actionbtn {
  @apply dark:text-gray-300 dark:border-gray-500 dark:hover:border-gray-400 sm:w-auto sm:text-sm;
}

.activityactionbtn {
  @apply mr-3;
}

.accesstokenbtn {
  @apply mr-3 text-xs text-gray-900 dark:text-gray-300;
}

.closemodalicon {
  @apply text-black text-opacity-30  hover:text-black 
  hover:text-opacity-40 dark:text-white dark:text-opacity-50 dark:hover:text-white dark:hover:text-opacity-60
   focus:outline-none focus:ring-2 focus:ring-offset-white dark:focus:ring-offset-gray-900 focus:ring-offset-2 
   focus:ring-gray-200 dark:focus:ring-gray-600 bg-gray-200 dark:bg-gray-600 p-0.5 rounded-2xl transition duration-200;
}

.closeSlideOvericon {
  @apply text-black rounded text-opacity-30  bg-gray-50 dark:bg-gray-600 hover:text-black hover:text-opacity-40 dark:text-white dark:text-opacity-50 dark:hover:text-white dark:hover:text-opacity-60 focus:outline-none focus:ring-2 focus:ring-offset-white dark:focus:ring-offset-gray-900 focus:ring-offset-2 focus:ring-blue-300 transition duration-200;
}

.formfooter {
  @apply px-4 pt-1 pb-4 bg-gray-200 border border-gray-200 rounded-b dark:border-gray-600 dark:bg-gray-600 sm:px-6;
}

.formbody {
  @apply px-4 bg-white border border-b-0 border-gray-200 rounded-t shadow dark:border-gray-600 dark:bg-gray-800 pt-2 pb-4;
}

.form-button-primary {
  @apply bg-blue-500  hover:bg-blue-600 focus:ring-blue-500 focus:ring-offset-gray-200 dark:focus:ring-offset-gray-600;
}

.form-button-success {
  @apply bg-green-500 hover:bg-green-600 focus:ring-green-500 focus:ring-offset-gray-200 dark:focus:ring-offset-gray-600;
}

.button {
  @apply inline-flex justify-center px-4 py-1.5 text-base font-medium text-white border border-transparent rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-300;
}

.button-primary {
  @apply bg-blue-500 hover:bg-blue-600 focus:ring-blue-500 dark:focus:ring-offset-gray-900 focus:ring-offset-gray-100 sm:w-auto;
}

.button-success {
  @apply bg-green-600 hover:bg-green-700 focus:ring-green-500 dark:focus:ring-offset-gray-900 focus:ring-offset-gray-100 sm:w-auto;
}

.button-danger {
  @apply bg-red-600 hover:bg-red-700 focus:ring-red-600 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900  sm:w-auto;
}

.button-transparent {
  @apply bg-gray-1500 hover:bg-gray-1300 dark:border-gray-500 dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-gray-300 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-blue-300 sm:w-auto;
}

.button-disabled {
  @apply bg-gray-400 dark:bg-gray-600 dark:text-red-500 dark:text-opacity-40 cursor-not-allowed text-red-500 text-opacity-40  sm:w-auto;
}

.button-success-disabled {
  @apply bg-gray-1500 dark:bg-gray-600 dark:text-gray-300 dark:text-opacity-40 cursor-not-allowed
   text-opacity-60 sm:w-auto hover:bg-gray-1500 
  dark:hover:bg-gray-600 focus:ring-gray-1500 dark:focus:ring-gray-600;
}

.control-card {
  @apply px-4 py-5 bg-white border border-gray-200 rounded dark:bg-gray-800 sm:p-6 dark:border-gray-600;
}

.modal-btn {
  @apply block px-2 py-2 text-xs font-bold leading-normal uppercase rounded focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-blue-500;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.hide-text {
  -webkit-text-security: disc;
}

.show-text {
  -webkit-text-security: none;
}

.caret:after {
  content: "";
  position: absolute;
  top: 0 !important;
  margin-left: 1rem;
  border-bottom: 10px solid var(--secondary-border-color);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

/* notistack message styling */
#notistack-snackbar {
  font-family: "Inter var", ui-sans-serif, system-ui !important;
  font-weight: 400 !important;
  font-display: swap;
  font-size: 14px !important;
}

/* microsoft logo style */
.mircosoft_logo {
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  margin-right: 0.25rem;
  top: auto;
}
