@import url("./base_variables.css");
.css-yk16xz-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--formcontrol-input-bg-color) !important;
  border-radius: 4px;
  cursor: pointer !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  /* height: 38px !important; */
  border: 1px solid var(--border-color) !important;
  color: var(--content-color) !important;
}

/* .css-yk16xz-control, */
/* .css-g1d714-ValueContainer {
  overflow-x: scroll;
} */

.css-yk16xz-control:focus {
  border: 1px solid var(--border-color) !important;
  /* box-shadow: 0 0 0 2px  #99c2ff; */
}

.css-yk16xz-control:hover {
  border: 1px solid var(--border-color) !important;
}

.css-1s2u09g-control {
  @apply dark:bg-gray-600 border border-gray-300 dark:border-gray-500 !important;
}

.css-1pahdxg-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--formcontrol-input-bg-color) !important;
  /* border-color: var(--focus-border-ring) !important; */
  border-radius: 4px;
  border-style: solid;
  /* border-width: 2px; */
  box-shadow: 0 0 0 2px #99c2ff !important;
  cursor: pointer !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  /* height: 38px !important; */
  color: var(--content-color) !important;
  border-color: none !important;
}

.css-1pahdxg-control:hover {
  /* border-color: var(--focus-border-ring) !important; */
  /* box-shadow: 0 0 0 2px #99c2ff; */
  border-color: none;
}

.css-1pahdxg-control:focus {
  /* border-color: var(--focus-border-ring) !important; */
  box-shadow: 0 0 0 2px #99c2ff;
  border-color: none;
}

.css-1wa3eu0-placeholder {
  @apply sm:text-sm !important;
  color: var(--placeholder-content-color) !important;
  padding: 5px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}

.css-b8ldur-Input {
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  box-sizing: border-box;
  color: var(--content-color) !important;
  border: none !important;
}

.css-b8ldur-Input > div > input:focus {
  box-shadow: none !important;
  border: none !important;
}

.css-qc6sy-singleValue,
.css-1uccc91-singleValue {
  color: var(--content-color) !important;
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 14px !important;
}

.css-1okebmr-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: var(--content-color) !important;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
  display: none !important;
  color: var(--content-color) !important;
}

.css-tlfecz-indicatorContainer {
  color: var(--content-color) !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
  color: var(--content-color) !important;
}

.css-1gtu0rj-indicatorContainer:hover {
  color: var(--content-color) !important;
}

.css-26l3qy-menu {
  top: 100%;
  /* background-color: var(--header-dropdown-background-color) !important; */
  border-radius: 4px;
  /* box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1); */
  margin-bottom: 8px;
  font-size: 14px !important;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 100 !important;
  box-sizing: border-box;
  color: var(--content-color) !important;
  @apply bg-gray-200 dark:bg-gray-600 !important;
}

.css-yt9ioa-option:active {
  background-color: var(--dropdown-background-hover-color) !important;
}

.css-9gakcf-option {
  background-color: var(--dropdown-background-hover-color) !important;
  color: var(--content-color) !important;
  cursor: pointer !important;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
  box-sizing: border-box;
}

.css-9gakcf-option:active {
  background-color: #2684ff;
}

.css-1n7v3ny-option {
  /* background-color: var(--header-dropdown-background-color) !important; */
  color: var(--content-color) !important;
  cursor: pointer !important;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  @apply dark:bg-gray-500 !important;
}

.css-1n7v3ny-option:active {
  /* background-color: var(--header-dropdown-background-color) !important; */
}

.css-1rhbuit-multiValue {
  background-color: var(--header-dropdown-background-color) !important;
}

.css-12jo7m5 {
  color: var(--title-color) !important;
  background-color: var(--login-screen-pin-box-border) !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.css-xb97g8 {
  background-color: var(--login-screen-pin-box-border) !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.css-xb97g8:hover {
  /* background-color: var(--header-link-active-color) !important; */
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.css-1fhf3k1-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--formcontrol-input-bg-color) !important;
  border: 1px solid var(--border-color) !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  overflow-x: visible;
}

.css-at12u2-loadingIndicator {
  color: var(--button-primary-color) !important;
}

.css-1xh8qep-loadingIndicator {
  color: var(--button-primary-color) !important;
}
