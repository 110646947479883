/* AUTH PAGES CLASSES*/

.ap-logo{
    @apply w-auto mx-auto text-blue-500 fill-current h-7 dark:text-white;
}

.gitlab-bg-color{
    background: #e65528;
}

.bitbucket-bg-color{
    background: #003f9e;
}