@import url("../src/Styles/base_variables.css");
@tailwind base;
@tailwind components;
@tailwind utilities;
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
  /* -webkit-text-security: disc !important;
  -moz-text-security: disc !important; */
}

.min-h-content {
  min-height: calc(100vh - 132px) !important;
}

/* START REACT CONTENT LOADER CLASSES */

.react_content_loader > defs > linearGradient > stop:nth-of-type(odd) {
  stop-color: var(--loader-background-color) !important;
}

.react_content_loader > defs > linearGradient > stop:nth-of-type(even) {
  stop-color: var(--loader-foreground-color) !important;
}

/* END REACT CONTENT LOADER CLASSES */

input[contenteditable="true"] {
  @apply block w-full px-2 py-2 placeholder-black border border-gray-300 rounded shadow-sm dark:text-gray-300 dark:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500 dark:focus:border-blue-500 sm:text-sm dark:border-gray-500 placeholder-opacity-50 dark:placeholder-white dark:placeholder-opacity-30;
}

/* width */
::-webkit-scrollbar {
  @apply w-2.5 xs:w-1 h-2.5;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-gray-100 dark:bg-gray-800;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-300 rounded dark:bg-gray-1500;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}
